import * as React from "react";
import { useIntl } from "react-intl";
import {
  handleSelectPreferedLocale,
  SupportedLocales,
  SupportedLocalesType,
  useFormatMessage,
} from "utils/locales";
import Link from "next/link";
import clsx from "clsx";

export interface HeaderProps {
  isSearchPage: boolean;
  logoEnterprise?: string;
  companyName?: string;
}

export function Header(props: HeaderProps) {
  const intl = useIntl();
  const formatMessage = useFormatMessage();

  const [isNavBarOpen, setIsNavBarOpen] = React.useState(false);
  const [showDropdownLocaleMenu, setDropdownLocaleMenu] = React.useState(false);

  const selectedLocale = SupportedLocales[intl.locale as SupportedLocalesType];

  const toggleNavBarVisibility = () => setIsNavBarOpen((prev) => !prev);

  const dropDownRef = React.createRef<any>();
  const selectLocaleBtnRef = React.createRef<any>();

  React.useEffect(() => {
    function handleClickOutside(event: any) {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setDropdownLocaleMenu(false);
      }
    }

    if (dropDownRef) {
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [dropDownRef, selectLocaleBtnRef]);

  return (
    <React.Fragment>
      <header
        id="top"
        className="flex flex-col w-full md:relative pin-t pin-r pin-l bg-headerBgColor"
      >
        <nav
          id="site-menu"
          className="fixed z-10 flex flex-col items-center justify-between w-full px-4 py-2 shadow-md bg-headerBgColor sm:flex-row sm:px-6 "
          data-cy="tracking.page.header"
        >
          <div className="flex flex-row flex-no-wrap items-center self-start justify-between w-full bg-headerBgColor md:w-auto md:self-center md:flex-none">
            <div className="py-1 no-underline hover">
              {!props.isSearchPage && props.logoEnterprise! ? (
                <Link href="/">
                  <img
                    className="pl-0"
                    src={props.logoEnterprise}
                    alt={props.logoEnterprise && props.companyName}
                    data-testid="KOT"
                  />
                </Link>
              ) : null}
              {!props.isSearchPage && !props.logoEnterprise ? (
                <Link href="/">
                  <h1
                    className="text-1.4rem font-bold text-big-title mobile:pl-0 capitalize"
                    data-testid="KOT"
                  >
                    {props.companyName}
                  </h1>
                </Link>
              ) : null}

              {props.isSearchPage && (
                <Link href="/">
                  <h1
                    className="text-1.4rem font-bold text-big-title mobile:pl-0"
                    data-testid="KOT"
                  >
                    KOTscan
                  </h1>
                </Link>
              )}
            </div>
            <button
              id="menuBtn"
              className={clsx(`block mr-3 hamburger md:hidden focus:outline-none`, {
                open: isNavBarOpen,
              })}
              type="button"
              onClick={toggleNavBarVisibility}
            >
              <span className="hamburger__top-bun"></span>
              <span className="hamburger__bottom-bun"></span>
            </button>
          </div>
          <div
            className={clsx(`block items-center md:flex`, { hidden: !isNavBarOpen })}
            style={{ flexBasis: "100%", width: "100%" }}
          >
            <ul id="menu" className={clsx(`navbar-menu ml-auto md:flex-row`)}>
              {!props.isSearchPage && (
                <li>
                  <Link href="/">
                    <a href="/" className="navbar-link">
                      {formatMessage("page_header__home_label")}
                    </a>
                  </Link>
                </li>
              )}
              <li className={"relative"}>
                <button
                  className="dropdown-toggle navbar-link"
                  onClick={() => setDropdownLocaleMenu(true)}
                >
                  {selectedLocale}
                </button>
                <ul
                  ref={dropDownRef}
                  className={clsx(
                    `locale-dropdown-menu static md:absolute md:float-left float-none`,
                    {
                      block: showDropdownLocaleMenu,
                    }
                  )}
                >
                  {Object.entries(SupportedLocales)
                    .filter((data) => data[1] !== selectedLocale)
                    .map(([locale, label]) => {
                      return (
                        <li className="" key={locale}>
                          <button
                            ref={selectLocaleBtnRef}
                            className="w-full px-6 py-1 text-left whitespace-no-wrap navbar-link text-secondary-400"
                            type="button"
                            onClick={() =>
                              handleSelectPreferedLocale(locale as SupportedLocalesType)
                            }
                          >
                            {label}
                          </button>
                        </li>
                      );
                    })}
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </React.Fragment>
  );
}
