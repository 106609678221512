import React, { FunctionComponent, ChangeEvent } from "react";

import { useFormatMessage } from "utils/locales";
interface BasicInputProps {
  className?: string;
  isError?: boolean | undefined;
  isRequired?: boolean | undefined;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  label?: string;
  placeHolder?: string;
  inputType?: string;
  value?: string;
  dataCypress?: string;
}

export const BasicInput: FunctionComponent<BasicInputProps> = (
  props: BasicInputProps
) => {
  const formatMessage = useFormatMessage();
  return (
    <React.Fragment>
      {props.inputType === "codeWordInput" && (
        <label className="text-label-input " htmlFor="colis">
          {formatMessage("package_search__package_number_label")}
        </label>
      )}
      {props.inputType === "phoneNumberInput" && (
        <label className="text-label-input" htmlFor="telephone">
          {formatMessage("package_search__package_tel_label")}
        </label>
      )}
      <input
        type="text"
        className={props.className}
        required={props.isRequired}
        name={props.label}
        id={props.label}
        placeholder={props.placeHolder}
        value={props.value}
        onChange={props.onChange}
        data-cy={props.dataCypress}
      />
      {props.isError && props.inputType === "codeWordInput" && (
        <p className="text-sm text-red-500">
          {" "}
          {formatMessage("package_search__package_valid_title")}
        </p>
      )}
      {props.isError && props.inputType === "phoneNumberInput" && (
        <p className="text-sm text-red-500">
          {" "}
          {formatMessage("package_search__phone_number_valid_title")}
        </p>
      )}
    </React.Fragment>
  );
};
