import * as React from "react";

export interface SpacerProps {
  direction?: "vertical" | "horizontal";
  size?: keyof typeof SpacingSizes;
}

const SpacingSizes = {
  xSmall: 1,
  small: 2,
  medium: 4,
  large: 6,
  xLarge: 8,
  xxLarge: 10,
};

/**
 * A Spacer component that allows elements to be spaced consistently without having to rely on the element model box spacing.
 *
 * On why this is useful https://mxstbr.com/thoughts/margin
 *
 * @param props SpacerProps
 */
export function Spacer({ size, direction }: SpacerProps) {
  let verticalDirectionStyles = {
    marginTop: SpacingSizes[size!] * 4,
  } as React.CSSProperties;

  let horizontalDirectionStyles = {
    marginLeft: SpacingSizes[size!] * 4,
  } as React.CSSProperties;

  return (
    <div
      aria-hidden="true"
      style={{
        display: "block",
        ...(direction === "vertical"
          ? verticalDirectionStyles
          : horizontalDirectionStyles),
      }}
    />
  );
}

Spacer.defaultProps = {
  direction: "vertical",
  size: "medium",
};
