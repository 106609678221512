import * as React from "react";
import clsx from "clsx";

export interface ButtonProps extends React.ButtonHTMLAttributes<any> {
  variant?: "primary" | "secondary";
  size?: "small" | "medium" | "large";
  startIcon?: React.ReactNode;
}

export function Button(props: ButtonProps) {
  const {
    type = "button",
    children,
    startIcon,
    className,
    size = "medium",
    variant = "primary",
    ...rest
  } = props;

  const isVariantPrimary = variant === "primary";
  const isVariantSecondary = variant === "secondary";

  return (
    <button
      tabIndex={0}
      type={type}
      className={clsx(
        `btn transition-colors`,
        {
          "bg-color-primary-main": isVariantPrimary,
          "text-white ": isVariantPrimary,
          "hover:bg-color-primary-brighter": isVariantPrimary,
          "bg-white": isVariantSecondary,
          "hover:text-white": isVariantSecondary,
          "text-secondary-400": isVariantSecondary,
          "hover:bg-color-primary-main": isVariantSecondary,
          "btn-size-large": size === "large",
          "btn-size-medium": size === "medium",
        },
        className
      )}
      {...rest}
    >
      <span className="w-full whitespace-nowrap" data-button-slot="label">
        {startIcon && <span data-button-slot="icon">{startIcon}</span>}
        {children}
      </span>
    </button>
  );
}
