import React, { useState } from "react";

import { GetServerSideProps } from "next";
import { useRouter } from "next/router";
import { Header } from "components/PageHeader";

import { useFormatMessage } from "utils/locales";
import { BasicInput } from "components/BasicInput";

import { Button } from "components/Button";
import { Spacer } from "components/Spacer";

const IndexPage: React.FunctionComponent = () => {
  const formatMessage = useFormatMessage();
  const router = useRouter();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    codeword: "",
    phoneNumber: "",
  });
  const [isError, setIsError] = useState(false);
  const [isErrorCodeWord, setIsErrorCodeWord] = useState(false);
  const [isErrorPhoneNumber, setIsErrorPhoneNumber] = useState(false);

  const [isClickTrack, setIsClickTrack] = useState(false);
  const onCodeWordChanged = (event: any) => {
    setIsErrorCodeWord(false);
    setIsErrorPhoneNumber(false);
    setIsError(false);
    setFormData({ ...formData, codeword: event.target.value });
  };

  const onPhoneNumberChanged = (event: any) => {
    setFormData({ ...formData, phoneNumber: event.target.value });
    setIsError(false);
  };

  const validateCodeWord = (codeword: string) => {
    const trimmedCodeWord = codeword.replace(/ /g, "");
    const codewordLetters = trimmedCodeWord.substr(0, 5);
    const codewordChiffres = trimmedCodeWord.substr(5, 9);
    if (/^[a-z]+$/i.test(codewordLetters) && /^[0-9]+$/.test(codewordChiffres)) {
      return true;
    } else {
      return false;
    }
  };
  const validatePhoneNumber = (phoneNumber: string) => {
    if (phoneNumber[0] === "+") {
      return true;
    } else {
      return false;
    }
  };

  const onValidateButtonClicked = async (e: any) => {
    let countError = 0;
    setIsClickTrack(true);
    setIsLoading(true);
    e.preventDefault();

    const trimmedPhoneNumber = formData.phoneNumber.replace(/ /g, "");
    const trimmedCodeWord = formData.codeword.replace(/ /g, "");

    const requestOptions = {
      method: "GET",
    };
    if (!validateCodeWord(trimmedCodeWord)) {
      setIsErrorCodeWord(true);
      countError++;
    }

    if (!validatePhoneNumber(trimmedPhoneNumber)) {
      setIsErrorPhoneNumber(true);
      countError++;
    }

    if (countError === 0) {
      setIsLoading(true);
      const phoneNumberFormat = trimmedPhoneNumber.replace(/\+/g, "%2b").trim();
      const res = await fetch(
        `${process.env.NEXT_PUBLIC_KOTSCAN_API_URL}/tracking?v=9&codeWord=${trimmedCodeWord}&phoneNumber=${phoneNumberFormat}`,
        requestOptions
      );
      if (res.status === 200) {
        setIsLoading(true);
        const result = await res.json();
        // window.location.assign("/" + result.tracking.parcel.trackingKey);
        router.push("/" + result.tracking.parcel.trackingKey);
      } else {
        setIsLoading(false);
        setIsError(true);
      }
      setIsClickTrack(false);
    } else {
      setIsClickTrack(false);
    }

    setIsLoading(false);
  };

  return (
    <div className="flex flex-col h-full">
      <Header isSearchPage={true} />
      <main role="main">
        <div className="container" data-cy="tracking.search.page">
          <section className="justify-center row">
            <div className="col">
              <h1 className="my-10 text-3xl !leading-header tracking-07rem font-bold text-center md:my-26 md:text-4xl text-big-title">
                {formatMessage("package_search__header_title")}
              </h1>
            </div>
          </section>
          <section className="row justify-content-center">
            <div className="col" />
          </section>
          <section className="row">
            <div className="md:col-4 md:order-2">
              <form onSubmit={onValidateButtonClicked}>
                {isError && (
                  <div
                    className="flex flex-col px-3 py-3 mb-4 text-red-500 bg-red-200 rounded-lg bg lative"
                    data-cy="packageNotExist"
                  >
                    <>{formatMessage("package_search__package_not_exist")}</>
                  </div>
                )}
                <div className="flex flex-col mb-4 ">
                  <BasicInput
                    className="input"
                    isRequired={true}
                    onChange={onCodeWordChanged}
                    label="codeword"
                    placeHolder="Ex. BISOU1212"
                    inputType="codeWordInput"
                    value={formData.codeword}
                    isError={isErrorCodeWord}
                    dataCypress="input.codeword"
                  />
                </div>
                <div className="flex flex-col mb-4 ">
                  <BasicInput
                    className="input"
                    isRequired={true}
                    onChange={onPhoneNumberChanged}
                    label="phoneNumber"
                    placeHolder="EX. +225611223344"
                    inputType="phoneNumberInput"
                    value={formData.phoneNumber}
                    isError={isErrorPhoneNumber}
                    dataCypress="input.phoneNumber"
                  />
                </div>
                <Spacer size="xxLarge" />
                <div className="mx-auto text-center">
                  <Button
                    id="btnTracking"
                    data-cy="button.track"
                    disabled={isClickTrack}
                    type="submit"
                    size="large"
                    startIcon={isLoading && <span className="spinner" />}
                  >
                    {formatMessage("package_search__track_button")}
                  </Button>
                </div>
              </form>
            </div>
            <div className="md:col-2 md:order-3" />
            <div className="mx-10 mb-10 md:col-5 md:order-1 mt-18">
              <img
                className="mr-auto mobile:w-full mobile:ml-0"
                src="./illu-home.svg"
                alt="Illu"
                width={360}
              />
            </div>
          </section>
        </div>
      </main>

      <footer className="py-4 mt-auto">
        <div className="w-full px-4 mx-auto">
          <div className="row">
            <div className="col">
              <img
                className="block mx-auto md:mx-0"
                src="./logo-keyopstech.svg"
                alt="keyops"
              />
            </div>
            <div className="md:col-6 col-12">
              <a
                href="https://keyops.tech"
                className="block text-base font-normal text-center text-secondary-300 font-roboto md:text-right"
              >
                keyops.tech
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export const getServerSideProps: GetServerSideProps = async () => {
  return {
    props: {
      id: 0,
    },
  };
};

export default IndexPage;
